import { Button } from "@mui/material";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../API/apiCollection";
import toast from "react-hot-toast";
import { resetState } from "../redux/cart";
import { orderCartDetailsReset } from "../redux/orderCartDetails";
import { deliveryAddressReset } from "../redux/DeliveryAddress";
import { resetStatePromo } from "../redux/Promocode";

const FlutterwavePaymentComponent = ({ amount, promoCode }) => {
  const settings = useSelector((state) => state?.Settings)?.settings
    ?.payment_gateways_settings;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const date = orderDetails && orderDetails.date;
  const time = orderDetails && orderDetails.slot;
  const orderNotes = orderDetails.orderNote;
  const profile = useSelector((state) => state.UserData);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handlePayout = async () => {
    try {
      const result = await api.placeOrder({
        method: "paypal",
        date: date,
        time: time,
        addressId:
          delivery_type === "Home" ? orderDetails?.selectedAddress?.id : "",
        order_note: orderNotes,
        promoCode: promoCode?.length > 0 ? promoCode[0]?.promo_code : "",
      });

      if (result.error === false || result.error === "false") {
        return result.data.order_id;
      } else {
        if (typeof result.message === "object") {
          Object.values(result.message).forEach((e) => {
            toast.error(e);
          });
        } else {
          toast.error(result.message);
        }
        throw new Error("Failed to place order");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Failed to place order. Please try again later.");
      throw error;
    }
  };

  const config = {

    public_key: settings.flutterwave_public_key, // Replace with your public key from Flutterwave
    tx_ref: Date.now().toString(),
    amount: amount, // Replace with the amount you want to charge
    currency: settings.flutterwave_currency_code,
    payment_options: "card, mobilemoneyghana, ussd",
    customer: {
      email: profile?.profile?.data?.email !== "" ? profile?.profile?.data?.email : "xyz@gmail.com",
      phonenumber: profile ? profile?.profile?.data?.phone : null,
      name: profile ? profile?.profile?.data?.username : null,
    },
    customizations: {
      title: process.env.REACT_APP_NAME,
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const initiatePayment = async () => {
    try {
      const newOrderId = await handlePayout();
      
      const handleSuccess = async () => {
        await api
          .add_transactions({ orderID: newOrderId, status: "success" })
          .then((result) => {
            dispatch(resetState());
            dispatch(orderCartDetailsReset());
            dispatch(deliveryAddressReset());
            dispatch(resetStatePromo());
          })
          .then((res) => {
            navigate("/profile/booking/services/" + newOrderId);
          });
      };

      const handleError = async () => {
        toast.error("Transaction has been cancelled");
        await api
          .add_transactions({
            orderID: newOrderId,
            status: "cancelled",
          })
          .then((result) => {
            navigate("/profile/booking/services/" + newOrderId);
          });
      };

      const handleCancel = async () => {
        toast.error("Transaction has been cancelled");
        await api
          .add_transactions({
            orderID: newOrderId,
            status: "cancelled",
          })
          .then((result) => {
            navigate("/profile/booking/services/" + newOrderId);
          });
      };

      handleFlutterPayment({
        callback: async (response) => {
          if (response.status === "successful") {
            await handleSuccess();
          } else {
            await handleError();
          }
          closePaymentModal();
        },
        onClose: async () => {
          await handleCancel();
        },
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      toast.error("Failed to initiate payment. Please try again later.");
    }
  };

  return (
    <Button
    variant="outlined"
    fullWidth
    sx={{ my: 1 }}
    onClick={initiatePayment}
  >
    {t("make_a_payment")} {t("with")} {t("flutterwave")}
  </Button>
  );
};

export default FlutterwavePaymentComponent;
