import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import Layout from '../layout/Layout';
import { Box, Breadcrumbs, Container, Skeleton, Typography } from '@mui/material';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { MdSupportAgent } from 'react-icons/md';
import api from '../../API/apiCollection';
import { FaFile, FaPlus } from 'react-icons/fa';
import { RiSendPlaneFill } from "react-icons/ri";
import { useSelector } from 'react-redux';
import { placeholderImage } from '../../util/Helper';
import { IoMdClose, IoMdDownload } from "react-icons/io";
import { getChatData, newchatData } from '../../redux/chatData';
import toast from 'react-hot-toast';
import CustomLightBox from '../LightBox/CustomLightBox';
import noChat from '../../Images/chat.svg'


const Chat = () => {
    const theme = useTheme();
    const newChat = useSelector(newchatData)
    const [newStoredChat, setNewStoredChat] = useState()
    const [chatList, setChatList] = useState([]);
    const [selectedChatTab, setSelectedChatTab] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [localChats, setLocalChats] = useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);

    const [message, setMessage] = useState('');
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [offset, setOffset] = useState(0);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isNotification, setIsNotification] = useState(false)
    const [isSending, setIsSending] = useState(false);
    const settingsData = useSelector((state) => state.Settings)?.settings;


    const MaxCharactersInTextMessage = settingsData?.general_settings?.maxCharactersInATextMessage
    const MaxFileSizeInMBCanBeSent = settingsData?.general_settings?.maxFileSizeInMBCanBeSent
    const MaxFilsOrImagesInOneMessage = settingsData?.general_settings?.maxFilesOrImagesInOneMessage


    useEffect(() => {
        const channel = new BroadcastChannel('firebase-messaging-channel');

        channel.addEventListener('message', (event) => {
            const notificationData = event?.data?.data;
            if (notificationData) {
                let newMessage = {
                    sender_details: { id: notificationData.sender_id },
                    timestamp: new Date().toISOString() // Add a timestamp if not provided in the notification
                };

                // Handle text message
                if (notificationData.message) {
                    newMessage.message = notificationData.message;
                }

                // Handle file
                if (notificationData.file) {
                    let parsedFile;
                    if (typeof notificationData.file === 'string') {
                        try {
                            parsedFile = JSON.parse(notificationData.file);
                        } catch (error) {
                            console.error('Error parsing file data:', error);
                            parsedFile = [];
                        }
                    } else {
                        parsedFile = notificationData.file;
                    }

                    // Flatten the nested array
                    const flattenedFiles = parsedFile.flat();

                    newMessage.file = flattenedFiles.map(file => ({
                        file: file.file || file.url,
                        file_name: file.file_name || file.name,
                        file_type: file.file_type || file.type
                    }));
                }

                setChatMessages(prevMessages => [newMessage, ...prevMessages]);
                scrollToBottom();
                setIsNotification(true);
            }
        });

        return () => {
            channel.close();
        };
    }, []);




    const userData = useSelector((state) => state.UserData)?.profile?.data;

    useEffect(() => {
        if (newChat) {
            setNewStoredChat(newChat);
            setSelectedChatTab(newChat)
        }
    }, [newChat]);

    const fetchList = async () => {
        try {
            const response = await api.fetch_providr_chat_list();
            let list = response?.data || [];

            if (!Array.isArray(list)) {
                list = [];
            }

            setChatList(list)
            setIsInitialLoading(false);

        } catch (error) {
            console.error('Error fetching chat list:', error);
            setIsInitialLoading(false);
        }
    };

    useEffect(() => {
        fetchList();
    }, []); // Fetch initial chat list on component mount

    const appendNewChat = (newChat) => {
        setChatList(prevChatList => {
    
            // Find an existing chat that matches the new chat by booking_id or partner_id
            const existingChatIndex = prevChatList.findIndex(chat => {
            
                const matchesBookingId = chat.booking_id === newChat.booking_id && newChat.booking_id !== null;
                const matchesPartnerId = chat.partner_id === newChat.partner_id && newChat.booking_id === null;
                return matchesBookingId || matchesPartnerId;
            });
    
            // If a matching chat is found, select it and return the list unchanged
            if (existingChatIndex !== -1) {
                setSelectedChatTab(prevChatList[existingChatIndex]);
                return prevChatList;
            }
    
            // If no matching chat is found, append the new chat at the top of the list and select it
            setSelectedChatTab(newChat); // Select the new chat
            return [newChat, ...prevChatList];
        });
    };
    
    useEffect(() => {

    }, [chatList, newStoredChat, selectedChatTab]);

    const handleChangeTab = (e, chat) => {
        e.preventDefault();
        setSelectedChatTab(chat);
        setIsAdmin(false)

    };

    const handleOpenLightbox = useCallback((index, images) => {
        setCurrentImages(images?.map(img => ({
            src: img.file,
            alt: img.file_name,
            type: img.file_type
        })));
        setCurrentImageIndex(index);
        setLightboxOpen(true);
    }, []);

    const handleCloseLightbox = useCallback(() => {
        setLightboxOpen(false);
    }, []);

    const gotoPrevious = useCallback(() => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex + currentImages.length - 1) % currentImages.length
        );
    }, [currentImages]);

    const gotoNext = useCallback(() => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex + 1) % currentImages.length
        );
    }, [currentImages]);

    const fetchChatMessages = async (selectedChatTab, newOffset = 0, append = false) => {

        setIsLoading(true);
        try {
            const response = await api.fetch_chat_history({
                type: isAdmin ? "0" : "1",
                booking_id: selectedChatTab?.booking_id,
                provider_id: selectedChatTab?.partner_id,
                limit: 25,
                offset: newOffset
            });
            const messages = response?.data;

            if (messages.length < 25) {
                setHasMore(false);
            }

            if (append) {
                setChatMessages(prevMessages => [...prevMessages, ...messages]);
            } else {
                setChatMessages(messages);
            }

            setOffset(newOffset);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleScroll = (e) => {
        const { scrollTop } = e.currentTarget;
        if (scrollTop === 0 && !isLoading && hasMore) {
            fetchChatMessages(selectedChatTab, offset + 25, true);
        }
    };
    useEffect(() => {
        if (selectedChatTab || isAdmin) {
            setOffset(0);
            setHasMore(true);
            fetchChatMessages(selectedChatTab);
        }
    }, [selectedChatTab, isAdmin]);

    const renderMessage = (message) => {
        const TextMessages = message && message.message;
        let files;

        if (typeof message?.file === 'string') {
            try {
                files = JSON.parse(message.file);
            } catch (error) {
                console.error('Error parsing file string:', error);
                files = [];
            }
        } else if (Array.isArray(message?.file)) {
            files = message.file;
        } else {
            files = [];
        }

        const FileMessages = files.length > 0;
        const TextAndFiles = TextMessages && FileMessages;

        const imageFiles = Array.isArray(files) ? files.filter(file =>
            file.file_type === 'image/jpeg' ||
            file.file_type === 'image/png' ||
            file.file_type === 'image/jpg' ||
            file.file_type === 'image/svg+xml'
        ) : [];

        if (TextAndFiles) {
            return (
                <div className="message-container">
                    <div className="text-message">
                        {message.message}
                    </div>
                    <div className="file-message">
                        {renderImageFiles(imageFiles && imageFiles)}
                        {renderNonImageFiles(files, imageFiles && imageFiles)}
                    </div>
                </div>
            );
        } else if (TextMessages) {
            return (
                <div className="text-message">
                    {message.message}
                </div>
            );
        } else if (FileMessages) {
            return (
                <div className="file-message">
                    {renderImageFiles(imageFiles)}
                    {renderNonImageFiles(files, imageFiles)}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderImageFiles = (imageFiles) => {
        return (

            <div className="image-preview">
                {imageFiles.slice(0, 5).map((file, index) => (
                    <div
                        key={index}
                        className="image-item-container"
                        onClick={() => handleOpenLightbox(index, imageFiles)}
                    >
                        {file.file_type === 'image/svg+xml' ? (
                            <img
                                src={file.file}
                                alt={file.file_name}
                                className="image-item svg-item"
                                onError={placeholderImage}
                            />
                        ) : (
                            <img
                                src={file.file}
                                alt={file.file_name}
                                className="image-item"
                                onError={placeholderImage}
                            />
                        )}
                    </div>
                ))}
                {imageFiles.length > 5 && (
                    <button
                        className="see-more-button"
                        onClick={() => handleOpenLightbox(0, imageFiles)}
                    >
                        {t("seeMore")} ({imageFiles.length - 5})
                    </button>
                )}
            </div>
        );
    };

    const renderNonImageFiles = (allFiles, imageFiles) => {
        const downloadFile = (fileUrl, fileName) => {
            var fileDownload = require('js-file-download');
            fileDownload(fileUrl, fileName);
        };

        // Ensure files is an array
        if (!Array.isArray(allFiles)) {
            console.error('Files is not an array:', allFiles);
            return null;
        }

        // Filter out empty objects and files that are in imageFiles
        const validFiles = allFiles.filter(file =>
            file &&
            Object.keys(file).length > 0 &&
            file.file &&
            file.file_name &&
            !imageFiles.includes(file)
        );

        return validFiles.length > 0 ? validFiles.map((file, index) => (
            file.file_type === 'video/mp4' ? (
                <div key={index} className="video-item">
                    <video controls>
                        <source src={file.file} type="video/mp4" />
                        {t("yourBrowserDoesNotSupport")}
                    </video>
                    <span className="file-info">{file.file_name}</span>
                </div>
            ) : (
                <button key={index} className="file-item" onClick={() => downloadFile(file.file, file.file_name)}>
                    <div className="file-info">
                        {file.file_name}
                    </div>
                    <span className="download-button">
                        <IoMdDownload />
                    </span>
                </button>
            )
        )) : null;
    };

    const ImageComponent = ({ image, alt }) => {
        if (!image || !image.src) {
            return null; // or some placeholder
        }

        if (image.type === 'image/svg+xml') {
            return (
                <img
                    src={image.src}
                    alt={alt}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    onError={placeholderImage}
                />
            );
        }
        return (
            <img
                src={image.src}
                alt={alt}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                onError={placeholderImage}
            />
        );
    };

    const handleMessageChange = (e) => {
        const newMessage = e.target.value;
        if (newMessage.length <= MaxCharactersInTextMessage) {
            setMessage(newMessage);
        } else {
            toast.error(`Message cannot exceed ${MaxCharactersInTextMessage} characters.`);
        }
    };

    const handleFileAttachment = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => {
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MaxFileSizeInMBCanBeSent) {
                toast.error(`File ${file.name} exceeds the maximum size of ${MaxFileSizeInMBCanBeSent}MB.`);
                return false;
            }
            return true;
        });

        if (attachedFiles.length + validFiles.length > MaxFilsOrImagesInOneMessage) {
            toast.error(`You can only attach up to ${MaxFilsOrImagesInOneMessage} files or images in one message.`);
            validFiles.splice(MaxFilsOrImagesInOneMessage - attachedFiles.length);
        }

        setAttachedFiles(prevFiles => [...prevFiles, ...validFiles]);
    };


    const removeAttachedFile = (index) => {
        setAttachedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };
    const isImageFile = (file) => {
        return file.type.startsWith('image/');
    };

    const renderFilePreview = (file, index) => {
        if (isImageFile(file)) {
            return (
                <div key={index} className="file-card image-card">
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                    <span>{file.name}</span>
                    <button onClick={() => removeAttachedFile(index)}>
                        <IoMdClose size={18} />
                    </button>
                </div>
            );
        } else {
            return (
                <div key={index} className="file-card">
                    <FaFile size={24} />
                    <span>{file.name}</span>
                    <button onClick={() => removeAttachedFile(index)}>
                        <IoMdClose size={18} />
                    </button>
                </div>
            );
        }
    };
    const scrollToBottom = () => {
        const chatScreen = document.querySelector('.chat_messages_screen');
        if (chatScreen) {
            chatScreen.scrollTop = chatScreen.scrollHeight;
        }
    };
    useEffect(() => {
        scrollToBottom();
    }, [chatMessages]);

    const handleAdminChat = () => {
        setIsAdmin(true)
        setSelectedChatTab(null)
    }


    const handleSend = async () => {
        if (message.trim() === '' && attachedFiles.length === 0) {
            toast.error('Please enter a message or attach a file before sending.');
            return;
        }

        setIsSending(true);

        const newMessage = {
            message: message,
            file: attachedFiles.map(file => ({
                file: URL.createObjectURL(file),
                file_name: file.name,
                file_type: file.type
            })),
            sender_details: { id: userData?.id },
            sender_id: userData?.id
        };

        try {
            const response = await api.send_chat_message({
                receiver_id: selectedChatTab?.partner_id,
                booking_id: selectedChatTab?.booking_id ? selectedChatTab?.booking_id : null,
                receiver_type: isAdmin ? "0" : "1",
                message: message,
                attachment: attachedFiles,
            });

            // Update chat messages
            setChatMessages(prevMessages => [newMessage, ...prevMessages]);
            setMessage('');
            setAttachedFiles([]);
            scrollToBottom();
            if (newStoredChat) {
                if (newStoredChat.booking_id !== null || selectedChatTab?.booking_id !== null) {
                    // If either has a booking_id, compare booking_ids
                    if (newStoredChat.booking_id === selectedChatTab?.booking_id) {
                        // Update the chat list and select the new chat
                        appendNewChat(newStoredChat);
                    } else {
                        getChatData(null);
                    }
                }
                else {
                    console.log(newStoredChat.partner_id === selectedChatTab?.partner_id)
                    // If both booking_ids are null, compare partner_ids
                    if (newStoredChat.partner_id === selectedChatTab?.partner_id) {
                        // Update the chat list and select the new chat
                        appendNewChat(newStoredChat);
                    } else {
                        getChatData(null);
                    }
                }
            }

        } catch (error) {
            console.log('error', error);
            toast.error(t('failedToSendMessage'));
        } finally {
            setIsSending(false);
        }
    };
    const renderSkeleton = () => (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ width: '30%', mr: 2 }}>
                    <Skeleton variant="rectangular" width="100%" height={400} />
                </Box>
                <Box sx={{ width: '70%' }}>
                    <Skeleton variant="rectangular" width="100%" height={400} />
                </Box>
            </Box>
        </Box>
    );

    return (
        <Layout>
            <>
                <Box paddingTop="35px" paddingBottom="35px" mt={2}>
                    <Container maxWidth="lg" className="mainContainer">
                        <Breadcrumbs
                            sx={{ paddingTop: '30px' }}
                            separator="|"
                            aria-label="breadcrumb"
                            className="mt-2 mb-1"
                        >
                            <Typography
                                component={Link}
                                to="/"
                                className="breadcrumb"
                                sx={{ color: theme.palette.color.breadcrum }}
                            >
                                <strong>{t('home')}</strong>
                            </Typography>
                            <Typography
                                className="breadcrumb"
                                sx={{ color: theme.palette.color.breadcrum }}
                            >
                                <strong>{t('Chat')}</strong>
                            </Typography>
                        </Breadcrumbs>
                    </Container>
                </Box>
                {isInitialLoading ? (
                    <Container className="mainContainer" sx={{ mb: '30px', mt: '30px' }}>
                        {renderSkeleton()}
                    </Container>
                ) : (


                    <Container className="mainContainer" sx={{ mb: '30px' }}>
                        <section id="chat_page">
                            <div className="chat_box row">
                                {chatList && chatList.length > 0 || newStoredChat ? (
                                    <>
                                        {chatList && chatList.length > 0 &&
                                            <div className="col-12 col-md-6 col-lg-4 p-0">
                                                <div className="chat_list">
                                                    <div className="list_header">
                                                        <div className="title">{t("chatList")}</div>
                                                        <div className="admin_chat">
                                                            <button onClick={handleAdminChat} className={`${isAdmin ? "active_admin" : "admin"}`}>
                                                                <MdSupportAgent size={22} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="list">
                                                        {chatList && chatList.map((chat, index) => (
                                                            <div
                                                                className={`provider ${selectedChatTab &&
                                                                    (
                                                                        (selectedChatTab.booking_id && selectedChatTab.booking_id === chat.booking_id) ||
                                                                        (!selectedChatTab.booking_id && !chat.booking_id && selectedChatTab.partner_id === chat.partner_id)
                                                                    )
                                                                    ? 'selected'
                                                                    : ''
                                                                    }`}
                                                                key={index}
                                                                onClick={(e) => handleChangeTab(e, chat)}
                                                            >
                                                                <div className="provider_details">
                                                                    <div className="provider_img_div">
                                                                        <img src={chat?.image} alt="" className="provider_img" onError={placeholderImage} />
                                                                    </div>
                                                                    <div className="provider_all">
                                                                        <span className="provider_name">{chat?.partner_name}</span>
                                                                        {chat?.booking_id !== null ? (
                                                                            <>
                                                                                <span className="booking_id">
                                                                                    <span>{t("bookingId")}</span>
                                                                                    <span>{chat?.booking_id}</span>
                                                                                </span>
                                                                                <span className="booking_status">
                                                                                    <span>{t("bookingStatus")}</span>
                                                                                    <span>{t(chat?.order_status)}</span>
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <span className="booking_id">{t("preBookingEnq")}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className={`col-12 col-md-6 ${chatList && chatList.length > 0 ? 'col-lg-8' : 'col-lg-12'} p-0`}>
                                            {isAdmin ? (
                                                <div className="chat_display">
                                                    <div className="chat_display_header">
                                                        <h4>{t("customerSupport")}</h4>
                                                    </div>
                                                    <div className="chat_messages_screen" onScroll={handleScroll}>
                                                        {isLoading ? (
                                                            <div className="loader_conatiner">
                                                                <div className="loading">{t("loading")}</div>
                                                            </div>
                                                        ) :
                                                            chatMessages && chatMessages.length > 0 ? (
                                                                <div className="messages">
                                                                    {chatMessages.map((message, index) => (
                                                                        <div className={`${(userData?.id === message?.sender_id || userData?.id === message?.sender_details?.id) ? "sender_message" : "other_message"}`} key={index}>
                                                                            {renderMessage(message)}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <div className="noChat">
                                                                    <span>{t("noChatMessageFound")}</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="chat_messages_inputs">
                                                        {attachedFiles.length > 0 && (
                                                            <div className="attached-files-preview">
                                                                <h4>{("selectedFiles")}</h4>
                                                                <div className="file-cards">
                                                                    {attachedFiles.map((file, index) => renderFilePreview(file, index))}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="attechment">
                                                            <input
                                                                type="file"
                                                                id="file-attachment"
                                                                style={{ display: 'none' }}
                                                                onChange={handleFileAttachment}
                                                                multiple
                                                            />
                                                            <button onClick={() => document.getElementById('file-attachment').click()}>
                                                                <FaPlus size={18} />
                                                            </button>
                                                        </div>
                                                        <div className="message_input">
                                                            <textarea
                                                                className='chat_message'
                                                                placeholder={t("typeHere")}
                                                                value={message}
                                                                onChange={handleMessageChange}
                                                            />
                                                            <span className="character-count">
                                                                {message.length}/{MaxCharactersInTextMessage}
                                                            </span>
                                                        </div>
                                                        <div className="send">
                                                            <button onClick={handleSend} disabled={isSending}>
                                                                {isSending ? (
                                                                    <div className="send_chat_loader"></div>  // You'll need to style this
                                                                ) : (
                                                                    <RiSendPlaneFill size={18} />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="chat_display">
                                                    {selectedChatTab && Object.keys(selectedChatTab).length > 0 ? (
                                                        <>
                                                            <div className="chat_display_header">

                                                                <div className="provider_img_div">
                                                                    <img src={selectedChatTab?.image} alt="" className="provider_img" onError={placeholderImage} />
                                                                </div>
                                                                <div className="provider_all">
                                                                    <span className="provider_name">{selectedChatTab?.partner_name}</span>
                                                                    {selectedChatTab?.booking_id !== null ? (
                                                                        <>
                                                                            {selectedChatTab?.booking_id &&
                                                                                <span className="booking_id">
                                                                                    <span>{t("bookingId")}</span>
                                                                                    <span>{selectedChatTab?.booking_id}</span>
                                                                                </span>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <span className="booking_id">{t("preBookingEnq")}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="chat_messages_screen" onScroll={handleScroll}>
                                                                {isLoading ? (
                                                                    <div className="loader_conatiner">
                                                                        <div className="loading">{t("loading")}</div>
                                                                    </div>
                                                                ) :
                                                                    chatMessages && chatMessages.length > 0 ? (
                                                                        <div className="messages">
                                                                            {chatMessages.map((message, index) => (
                                                                                <div className={`${userData?.id === message?.sender_details?.id ? "sender_message" : "other_message"}`} key={index}>
                                                                                    {renderMessage(message)}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="noChat">
                                                                            <span>{t("noChatMessageFound")}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            {selectedChatTab?.order_status === "cancelled" || selectedChatTab?.order_status === "completed" ?
                                                                (
                                                                    <div className="no_send_message">
                                                                        <p>{t("sorryMessage")}</p>
                                                                    </div>

                                                                ) : (
                                                                    <div className="chat_messages_inputs">
                                                                        {attachedFiles.length > 0 && (
                                                                            <div className="attached-files-preview">
                                                                                <h4>{t("selectedFiles")}</h4>
                                                                                <div className="file-cards">
                                                                                    {attachedFiles.map((file, index) => renderFilePreview(file, index))}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className="attechment">
                                                                            <input
                                                                                type="file"
                                                                                id="file-attachment"
                                                                                style={{ display: 'none' }}
                                                                                onChange={handleFileAttachment}
                                                                                multiple
                                                                            />
                                                                            <button onClick={() => document.getElementById('file-attachment').click()}>
                                                                                <FaPlus size={18} />
                                                                            </button>
                                                                        </div>
                                                                        <div className="message_input">
                                                                            <textarea
                                                                                className='chat_message'
                                                                                placeholder="type something here..."
                                                                                value={message}
                                                                                onChange={handleMessageChange}
                                                                            />
                                                                            <span className="character-count">
                                                                                {message.length}/{MaxCharactersInTextMessage}
                                                                            </span>
                                                                        </div>
                                                                        <div className="send">
                                                                            <button onClick={handleSend}>
                                                                                <RiSendPlaneFill size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </>

                                                    ) : (
                                                        <div className="noChat">
                                                            <div className="noChatStartConversation">
                                                                <img src={noChat} onError={placeholderImage} className='noChatFound' />
                                                            </div>
                                                            <div className='d-flex flex-column align-items-center justify-content-center text-center p-3'>
                                                                <h3>{t("welcomeTo")} {""} {settingsData?.general_settings?.short_description}</h3>
                                                                <span className='fw-bold'>{t("pickChat")}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </>

                                ) :
                                    <>
                                        <div className="col-12 p-0 width-100 hight-100">
                                            <div className="noChat p-5">
                                                <div className="noChatStartConversation">
                                                    <img src={noChat} onError={placeholderImage} className='noChatFound' />
                                                </div>
                                                <div className='d-flex flex-column align-items-center justify-content-center text-center p-3'>
                                                    <h3>{t("chatNotFound")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </section>
                    </Container>
                )}
                {lightboxOpen && (
                    <>
                        <CustomLightBox lightboxOpen={lightboxOpen} currentImages={currentImages} currentImageIndex={currentImageIndex} handleCloseLightbox={handleCloseLightbox} gotoNext={gotoNext} gotoPrevious={gotoPrevious} />
                    </>
                )}

            </>
        </Layout >
    );
};

export default Chat;